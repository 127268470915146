/* TODO: Remove tailwind completely */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
} 

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

h1 {
  font-weight: 600;
  font-size: 3.5rem;
  line-height: 3.4rem;
  /* letter-spacing: 0.025rem; */
}